.ycs-title-llamadas-concurrentes{
  text-align: center;
}
.ycs-container-llamadas-concurrentes{
  background-color: #FFF;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px 0 60px 0;
}
.ycs-container-llamadas-atendidas{
  background-color: #FFF;
  box-sizing: border-box;
  /* height: calc( (100vh - 63px - 56px) / 5) + 80px; */
  margin-bottom: 10px;
  padding: 20px 0 60px 0;
}
.ycs-graph{
  box-sizing: border-box;
  height: calc( (100vh - 63px - 56px) / 5);
  padding: 0 5px;
}
.ycs-mini-graph{
  box-sizing: border-box;
  height: calc( (100vh - 63px - 56px + 200px) / 5);
  padding: 0 5px;
}

/* Empty graph */
.ycs-empty-graph{
  background-color: #FFF;
  padding: 10px;
  text-align: center;
}

/* Customed tooltip */
.ycs-container-tooltip{
  background-color: #FFF;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-sizing: border-box;
  height: 150px;
  overflow-y: auto;
  padding: 5px 10px;
  pointer-events: auto;
}

/* Responsive */
@media (max-width: 768px){
  .ycs-graph{
    width: 700px;
  }
}