.ycs-sideBarLogo{
  align-items: center;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  height: 63px;
}
.ycs-container-sidebar{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 63px);
}
.ycs-container-sidebar > ul{
  background-color: #000;
}